<template>
  <table class="table table-hover table-sm">
    <thead>
    <tr>
      <th>Sıra No</th>
      <th>Kısa Kod</th>
      <th>Tedarikçi Adı</th>
      <th> Termin <br> (Varsayılan)</th>
      <th>Terminler</th>

      <th> Tarih<br> (Ekleme/Düzeltme)</th>
      <th>Yönet</th>
    </tr>
    </thead>
    <tbody>
    <Supplier
        v-for="supplier in _suppliers"
        :key="supplier.id"
        :supplier="supplier"/>
    </tbody>
  </table>
</template>

<script>
import Supplier from "@/views/supplier/Supplier";
import {mapGetters} from "vuex";

export default {
  name: "Suppliers",
  components: {Supplier},
  computed: {
    ...mapGetters('supplier', ["_fields", "_suppliers"])
  },
}
</script>

<style scoped>

</style>
