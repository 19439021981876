<template>
  <div>
    <div>
      <b-form
          ref="form"
          :style="{height: trHeight}"
          class="repeater-form"
          @submit.prevent="repeateAgain"
      >
        <!-- Row Loop -->
        <b-row
            v-for="(item, index) in items"
            :id="item.id"
            :key="item.id"
            ref="row"
        >
          <!-- Item Name -->
          <b-col md="4">
            <b-form-group
                label="Maksimum Adet"
                label-for="mak-adet"
            >
              <b-form-input
                  id="max-adet"
                  type="number"
                  v-model="item.maxStock"
              />
            </b-form-group>
          </b-col>

          <!-- Cost -->
          <b-col md="4">
            <b-form-group
                label="Termin Gün"
                label-for="deadline-day"
            >
              <b-form-input
                  id="deadline-day"
                  type="number"
                  v-model="item.deadline"
              />
            </b-form-group>
          </b-col>

          <!-- Remove Button -->
          <b-col
              md="4"
          >
            <b-button
                variant="outline-danger"
                size="sm"
                class="mt-0 mt-md-2"
                @click="removeItem(index)"
            >
              <feather-icon
                  icon="XIcon"
                  class="mr-25"
              />
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </div>

    <b-button-group>
      <b-button
          variant="primary"
          size="sm"
          @click="repeateAgain"
      >
        <feather-icon
            icon="PlusIcon"
            class="mr-25"
        />
      </b-button>
      <b-button
          variant="success"
          size="sm"
          @click="saveDeadlines"
      >
        <feather-icon
            icon="SaveIcon"
            class="mr-25"
        />
      </b-button>
    </b-button-group>
  </div>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BButtonGroup
} from 'bootstrap-vue'
import {heightTransition} from '@core/mixins/ui/transition'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BButtonGroup
  },
  mixins: [heightTransition],
  data() {
    return {
      nextTodoId: 2,
    }
  },
  props: {
    items: {
      default: [],
      type: Array,
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    saveDeadlines() {
      alert();
    }
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
