<template>
  <b-form @submit.prevent>
    <b-row>
      <b-col md="10">

      </b-col>

      <b-col md="2">
        <b-form-group label="Yönetim">
          <b-button-group size="sm">
            <!-- Butonlar Buraya Gelecek -->
            <b-button variant="primary" @click="getAll">Getir</b-button>
            <b-button variant="danger" @click="clearSearch">Temizle</b-button>
            <b-dropdown
                size="sm"
                text="Tercih"
                variant="outline-dark"
            >
            </b-dropdown>
            <!-- Butonlar Buraya Gelecek -->
          </b-button-group>
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BForm,
  BButtonGroup,
  BButton,
  BFormGroup,
  BFormInput,
  BDropdown,
  BFormCheckboxGroup
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  name: "Search",
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BForm,
    BButtonGroup,
    BFormCheckboxGroup,
    BButton,
    BFormGroup,
    BDropdown,
    BFormInput,
    vSelect
  },
  computed: {},
  methods: {
    getAll() {
      this.$store.dispatch('supplier/suppliers');
    },
    clearSearch() {
      alert()
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
