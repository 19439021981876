<template>
  <tr>
    <td> {{ supplier.id }}</td>
    <td>{{ supplier.code }}</td>
    <td>{{ supplier.name }}</td>
    <td>{{ supplier.deafault_deadline }}</td>

    <td>
      <b-row v-for="(deadline,index) in supplier.deadlines" :key="index">
        <b-col>
          <b-input-group>
            <b-form-input type="number" size="sm" v-model="deadline.maxStock"/>
            <b-form-input type="number" size="sm" v-model="deadline.deadline"/>
            <b-input-group-append>
              <b-button size="sm" variant="danger">
                <feather-icon icon="Trash2Icon"/>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </td>


    <td>
      {{
        new Date(supplier.created_at).toLocaleString("tr-TR", {dateStyle: "short", timeStyle: "short",})
      }} <br>

      {{
        new Date(supplier.updated_at).toLocaleString("tr-TR", {dateStyle: "short", timeStyle: "short",})
      }}
    </td>
    <td>
      <b-dropdown
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
      >
        <template v-slot:button-content>
          <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="text-body align-middle mr-25"
          />
        </template>
        <b-dropdown-item>
          <feather-icon
              icon="Edit2Icon"
              class="mr-50"
          />
          <span>Edit</span>
        </b-dropdown-item>
        <b-dropdown-item>
          <feather-icon
              icon="TrashIcon"
              class="mr-50"
          />
          <span>Delete</span>
        </b-dropdown-item>
      </b-dropdown>
    </td>
  </tr>
</template>

<script>
import EditDeadline from "@/views/supplier/EditDeadline";

export default {
  name: "Supplier",
  components: {EditDeadline},
  props: {
    supplier: {
      type: Object,
      required: true
    }
  },
}
</script>

<style scoped>

</style>
