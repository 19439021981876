<template>
  <section id="card-text-alignment">
    <section id="search-section">
      <b-row>
        <b-col md="12">
          <b-card>
            <b-card-body>
              <Search></Search>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </section>


    <section id="items">
      <b-row id="table-hover-row">
        <b-col md="12">
          <b-card>
            <b-card-body>
              <div class="table-responsive">
                <Suppliers/>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </section>


  </section>
</template>

<script>
import Search from "@/views/supplier/Search";
import Suppliers from "@/views/supplier/Suppliers";
import {BRow, BCol, BCard, BCardBody} from "bootstrap-vue";

export default {
  name: "SupplierHome",
  components: {Search, Suppliers, BRow, BCol, BCard, BCardBody}
}
</script>

<style scoped>

</style>
